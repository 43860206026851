import React, { useEffect, useState } from 'react'; 
import { CONFIGS_URL, getFetchRequest, postFetchRequest } from '../store/requests';
import { makeUrl, errorCallback } from '../constant';
import { CheckIconThin } from '../appearence/icons/check_icon_thin';

const CONFIG_CAN_GUEST_SEE_PRICES = 'guest-can-see-prices';
const CONFIG_CAN_GUEST_SEE_PRICES_RU = 'Гости могут видеть цены:';
const CONFIG_MOEX_PERCENTAGE_INCREASE = 'moex-percentage-increase';
const CONFIG_MOEX_PERCENTAGE_INCREASE_RU = 'коэффициент конвертации moex (в %)';  // Эта строка больше не будет использоваться
const CONFIG_DEFAULT_CUSTOMER_COEFFICIENT = 'default-customer-coefficient';
const CONFIG_DEFAULT_CUSTOMER_COEFFICIENT_RU = 'коэффициент повышения цены для новых пользователей';
const CONFIG_MAX_PRODUCTS_LOADING_PER_MINUTE = 'max-products-loading-per-minute';
const CONFIG_MAX_PRODUCTS_LOADING_PER_MINUTE_RU = 'максимальное количество загружаемых продуктов за минуту';
const CONFIG_MAX_CURRENTLY_LOADING_PRODUCTS = 'max-currently-loading-products';
const CONFIG_MAX_CURRENTLY_LOADING_PRODUCTS_RU = 'максимальное количество одновременно загружаемых продуктов';
const CONFIG_TYPE_BOOLEAN = 'boolean';
const CONFIG_TYPE_STRING = 'string';
const CONFIG_TYPE_NUMBER = 'number';
const CONFIG_TYPE_FLOAT = 'float';

const CHECK_NUMBER_TYPE_REGEX = /^\d+$/;
const CHECK_FLOAT_TYPE_REGEX = /^-?\d*\.?\d*$/;

const transformConfigNameToTitle = (title) => {
    if(title === CONFIG_CAN_GUEST_SEE_PRICES) {
        return CONFIG_CAN_GUEST_SEE_PRICES_RU;
    }
    if(title === CONFIG_DEFAULT_CUSTOMER_COEFFICIENT) {
        return CONFIG_DEFAULT_CUSTOMER_COEFFICIENT_RU;
    }
    if(title === CONFIG_MAX_PRODUCTS_LOADING_PER_MINUTE) {
        return CONFIG_MAX_PRODUCTS_LOADING_PER_MINUTE_RU;
    }
    if(title === CONFIG_MAX_CURRENTLY_LOADING_PRODUCTS) {
        return CONFIG_MAX_CURRENTLY_LOADING_PRODUCTS_RU;
    }
    return '';
};

const validateInputValue = (configType, value) => {
    let result = true;
    if(value !== '' && configType === CONFIG_TYPE_NUMBER && !value.match(CHECK_NUMBER_TYPE_REGEX)) {
        result = false;
    }
    if(value !== '' && configType === CONFIG_TYPE_FLOAT && !value.match(CHECK_FLOAT_TYPE_REGEX)) {
        result = false;
    }
    return result;
}

const sortConfigsList = (a,b) => a.configType.localeCompare(b.configType) || a.configName.localeCompare(b.configName);

const ConfigurationTab = ({ isCollapsed }) => {
    const [configsList, setConfigsList] = useState([]);

    const successGetSettingsCallback = (result) => {
        setConfigsList(result);
    };

    useEffect(() => {
        getFetchRequest(makeUrl([CONFIGS_URL]), successGetSettingsCallback, errorCallback);
    }, []);

    const changeCheckboxValue = (e, id) => {
        configsList.forEach(it => it.id === id ? it.isActive = e.target.checked : null);
        setConfigsList([...configsList]);
        postFetchRequest(makeUrl([CONFIGS_URL, id, '/']), { configValue: null, isActive: e.target.checked }, () => {}, errorCallback, true);
    };

    const changeInputValue = (e, id) => {
        if(!e.target.value.includes('-')) {
            const configItem = configsList.find(it => it.id === id);
            const value = e.target.value;
            if(!validateInputValue(configItem.configType, value)) {
                return;
            }
            configItem.configValue = value;
            setConfigsList([...configsList]);
            postFetchRequest(makeUrl([CONFIGS_URL, id, '/']), { configValue: value, isActive: configItem.isActive}, () => {}, errorCallback, true);
        }
    };

    try {
        return (
            <section className={`workspace ${isCollapsed === null ? '' : isCollapsed ? 'workspace_collapsed' : 'workspace_full-width'} config__page`}>
                <h1 className="config__title">Конфигурационные параметры приложения</h1>
                <ul className="config__list">
                    {configsList
                        .filter(config => config.configName !== CONFIG_MOEX_PERCENTAGE_INCREASE)
                        .sort(sortConfigsList)
                        .map(config => (
                            <li key={config.id} className="config__item">
                                <p className="config__subtitle">{transformConfigNameToTitle(config.configName)}</p>
                                {config.configType === CONFIG_TYPE_BOOLEAN ? (
                                    <label className="config__label">
                                        <input type="checkbox" className="config__checkbox" checked={config.isActive} onChange={(e) => changeCheckboxValue(e, config.id)}/>
                                        <CheckIconThin nameOfClass="config__checkbox-icon" />
                                    </label>
                                ) : (
                                    <input 
                                        className="config__input" 
                                        min="0"
                                        type={config.configType === CONFIG_TYPE_STRING ? "text" : "number"} 
                                        value={config.configValue} 
                                        onChange={(e) => changeInputValue(e, config.id)}
                                    />
                                )}
                            </li>
                        ))}
                </ul>
            </section>
        );
    } catch (error) {
        return <ErrorHandler />;
    }
};

export default ConfigurationTab;
